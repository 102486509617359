import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles
import 'boxicons/css/boxicons.min.css'
import "@fontsource/poppins"; 
import VueKonva from 'vue-konva';

Vue.use(VueKonva);

Vue.use(Vuesax, {
  // options here
  colors: {
    primary: '#b648f6'
  }
})
Vue.use(VueRouter)

const routes = [
  { 
    name: 'feed',
    path: '/feed',
    component: () => import('./pages/Feed.vue'),
    meta: { loggedIn: true, isAdmin: false }
  },

  { 
    name: 'store',
    path: '/store/:productId/:orderDraftId?',
    component: () => import('./pages/Store.vue'),
    meta: { loggedIn: true, isAdmin: false },
    props: true
  },

  { 
    name: 'preview',
    path: '/preview/:orderId',
    component: () => import('./pages/Preview.vue'),
    meta: { loggedIn: true, isAdmin: false },
    props: true
  },

  { 
    name: 'user',
    path: '/user/:tab',
    component: () => import('./pages/User.vue'),
    meta: { loggedIn: true, isAdmin: false },
    props: true
  },

  { 
    name: 'checkout',
    path: '/checkout',
    component: () => import('./pages/Checkout.vue'),
    meta: { loggedIn: true, isAdmin: false },
    props: true
  },

  { 
    name: 'admin',
    path: '/admin',
    component: () => import('./pages/Admin.vue'),
    meta: { loggedIn: true, isAdmin: true }
  },

  { 
    name: 'homepage',
    path: '/',
    component: () => import('./pages/Homepage.vue'),
    meta: { loggedIn: false, isAdmin: false }
  },

  { 
    name: 'faq',
    path: '/faq',
    component: () => import('./pages/FAQ.vue'),
    meta: { loggedIn: false, isAdmin: false }
  },

  { 
    name: 'about',
    path: '/about',
    component: () => import('./pages/About.vue'),
    meta: { loggedIn: false, isAdmin: false }
  },

  { 
    name: 'tos',
    path: '/tos',
    component: () => import('./pages/TOS.vue'),
    meta: { loggedIn: false, isAdmin: false }
  },

  { 
    name: 'cookie-policy',
    path: '/cookie-policy',
    component: () => import('./pages/CookiePolicy.vue'),
    meta: { loggedIn: false, isAdmin: false }
  },

  { 
    name: 'privacy-policy',
    path: '/privacy-policy',
    component: () => import('./pages/PrivacyPolicy.vue'),
    meta: { loggedIn: false, isAdmin: false }
  },

  { 
    name: 'afterCheckout',
    path: '/checkout/done',
    component: () => import('./pages/AfterCheckout.vue'),
    meta: { loggedIn: true, isAdmin: false },
    props: true

  },

  {
    name: 'ssr',
    path: '/cgi-bin/ssr',
    component: () => import('./pages/SSR.vue'),
    meta: { loggedIn: false, isAdmin: false }
    
  },

  {
    name: 'drafts',
    path: '/drafts',
    component: () => import('./pages/Drafts.vue'),
    meta: { loggedIn: true, isAdmin: false }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

window.addEventListener("load", function() { // Inizializzo vue solo dopo che il caricamento della pagina è completo (tutti gli altri assets js caricati)
  new Vue({
    router: router,
    el: '#app',
    render: h => h(App)
  })
});
