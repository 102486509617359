<template>
<div id="app">
    <vs-dialog v-model="resetPasswordDialog" blur prevent-close :loading="resetPasswordDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Non ricordi la <b>password</b>?
            </h4>

        </template>

        <div class="con-form">
            <vs-row justify="center">
                <p style="margin: 0;">Reimposta le tue credenziali!</p>
            </vs-row>
            
            <vs-input type="password" v-model="resetPasswordDialogPassword" placeholder="Password" block>
                <template #icon>
                    <i class='bx bxs-lock'></i>
                </template>
            </vs-input>
            <vs-input type="password" v-model="resetPasswordDialogRPassword" placeholder="Ripeti password" block>
                <template #icon>
                    <i class='bx bxs-lock'></i>
                </template>
            </vs-input>
        </div>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="resetPassword">
                    Reimposta
                </vs-button>

                <div class="new">
                    Non devi reimpostare la password? <a @click="showSignIn">Accedi ora!</a>
                </div>
            </div>
        </template>
    </vs-dialog>

    <vs-dialog v-model="recoverPasswordDialog" blur prevent-close :loading="recoverPasswordDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Non ricordi la <b>password</b>?
            </h4>

        </template>

        <div class="con-form">
            <vs-row justify="center">
                <p style="margin: 0;">Reimposta le tue credenziali!</p>
            </vs-row>
            
            <vs-input v-model="recoverPasswordDialogEmail" placeholder="Email" block>
                <template #icon>
                    @
                </template>
            </vs-input>
        </div>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="recoverPassword">
                    Recupera password
                </vs-button>

                <div class="new">
                    Non devi reimpostare la password? <a @click="showSignIn">Accedi ora!</a>
                </div>
            </div>
        </template>
    </vs-dialog>

    <vs-dialog v-model="signInDialog" blur prevent-close :loading="userLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Benvenuto in <b>RemindMe</b>
            </h4>

        </template>

        <div class="con-form">
            <vs-row justify="center">
                <p style="margin: 0;">Effettua l'accesso per iniziare!</p>
            </vs-row>
            <vs-input v-model="email" placeholder="Email" v-on:keyup.enter="signIn" block>
                <template #icon>
                    @
                </template>
            </vs-input>
            <vs-input type="password" v-model="password" placeholder="Password" v-on:keyup.enter="signIn" block>
                <template #icon>
                    <i class='bx bxs-lock'></i>
                </template>
            </vs-input>
        </div>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="signIn">
                    Accedi
                </vs-button>

                <div class="new">
                    Nuovo da queste parti? <a @click="showSignUp">Crea un account!</a>
                </div>
                <div class="new">
                    Non ricordi le tue credenziali? <a @click="showRecoverPassword">Reimposta la password!</a>
                </div>
            </div>
        </template>
    </vs-dialog>

    <vs-dialog v-model="cookieDialog" blur prevent-close>
        <template #header>
            <h4 style="margin-bottom: 0;">
                Oh no!
            </h4>

        </template>

        <div class="con-form">
            <vs-row justify="center">
                <p style="margin: 0;">Sembra che tu non abbia ancora accettato la nostra cookie policy!</p>
            </vs-row>
            <vs-row justify="center">
                <p style="margin: 0;">Accetta l'utilizzo dei cookie per proseguire!</p>
            </vs-row>
            <vs-row justify="center" style="margin-top: 20px; margin-bottom: 20px;">
                <img style="width: 40%;" src="./assets/accept_cookie.png" />
            </vs-row>
        </div>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="acceptCookie">
                    Accetto!
                </vs-button>

                <div class="new">
                    Maggiori informazioni? <a>Cookie policy</a>
                </div>
            </div>
        </template>
    </vs-dialog>

    <vs-dialog v-model="signUpDialog" blur prevent-close :loading="userLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Benvenuto in <b>RemindMe</b>
            </h4>

        </template>

        <div class="con-form">
            <vs-row justify="center">
                <p style="margin: 0;">Iscriviti per iniziare la tua esperienza!</p>
            </vs-row>
            <vs-input block v-model="email" placeholder="Email" v-on:keyup.enter="signUp">
                <template #icon>
                    @
                </template>
            </vs-input>
            <vs-input block v-model="nome" placeholder="Nome" v-on:keyup.enter="signUp">
                <template #icon>
                    <i class='bx bxs-user'></i>
                </template>
            </vs-input>
            <vs-input block type="password" v-model="password" placeholder="Password" v-on:keyup.enter="signUp">
                <template #icon>
                    <i class='bx bxs-lock'></i>
                </template>
            </vs-input>
            <vs-input block type="password" v-model="rpassword" placeholder="Ripeti password" v-on:keyup.enter="signUp">
                <template #icon>
                    <i class='bx bxs-lock'></i>
                </template>
            </vs-input>
            <vs-checkbox v-model="tos">
                Accetto i <router-link to="/tos" style="margin-left: 4px;">termini e condizioni</router-link>
            </vs-checkbox>
        </div>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="signUp">
                    Registrati
                </vs-button>

                <div class="new">
                    Hai già un account? <a @click="showSignIn">Accedi!</a>
                </div>
            </div>
        </template>
    </vs-dialog>
    <!--<vs-navbar center-collapsed v-model="active">
        <template #left>
            <img src="./assets/logo.png" alt="">
        </template>
        <vs-navbar-item :active="active == 'feed'" id="feed">
            Feed
        </vs-navbar-item>
        <vs-navbar-item :active="active == 'store'" id="store">
            Store
        </vs-navbar-item>
        <vs-navbar-item :active="active == 'ordini'" id="ordini">
            Ordini
        </vs-navbar-item>
        <vs-navbar-item :active="active == 'account'" id="account">
            Account
        </vs-navbar-item>
        <vs-navbar-item :active="active == 'admin'" id="admin" v-if="user != null && user.isAdmin">
            Amministrazione
        </vs-navbar-item>
        <template #right>
            <vs-button style="margin:0;" animation-type="scale" block>
                Scopri prodotti <i class='bx bx-right-arrow-alt bx-sm'></i>
                <template #animate>
                    Configura ora
                </template>
            </vs-button>
        </template>
    </vs-navbar>-->
    <div class="page">
        <header class="header">
            <div class="header__center center">
                <router-link to="/" class="header__logo"><img class="header__pic header__pic_dark" src="./assets/logo-dark.png" alt="" /><img class="header__pic header__pic_light" src="./assets/logo-dark.png" alt="" /></router-link>
                <div class="header__wrapper" :class="{'visible': burgerMenu}">
                    <vs-row direction="row" class="nav-group" style="align-items: center; justify-content: space-between; height: 100%;">
                        <nav class="header__nav">
                            <template v-if="$route.name != 'store'">
                                <router-link to="/" class="header__link">Homepage</router-link>
                                <router-link v-if="user != null" to="/feed" class="header__link">i Miei Ricordi</router-link>
                                <router-link to="/about" class="header__link">Chi siamo</router-link>
                                <!--<router-link to="/store" class="header__link">Prodotti</router-link>-->
                                <router-link to="/faq" class="header__link">FAQ</router-link>
                                <router-link to="/admin" class="header__link" v-if="user != null && user.isAdmin">Amministrazione</router-link>
                            </template>
                        </nav>
                        <div class="header__btns" v-if="user == null">
                            <vs-button transparent size="large" @click="showSignIn">Accedi</vs-button>
                            <vs-button size="large" @click="showSignUp">Registrati</vs-button>
                        </div>
                        <div v-else class="header__btns" style="cursor: pointer;" @click="userMenu = !userMenu">
                            <vs-tooltip bottom v-model="userMenu" not-hover>
                                <vs-row align="center" style="gap: 10px;">
                                    <vs-avatar history history-gradient circle>
                                        <img :src="baseUrl + '/User/Media'" alt="" v-if="user.proPicFileId != null" />
                                        <template #text v-if="user.proPicFileId == null">
                                            {{ user.invoicingDetails.name }} {{ user.invoicingDetails.surname != null ? user.invoicingDetails.surname : ''}}
                                        </template>
                                    </vs-avatar>
                                    <p>Benvenuto, <b style="color: #b648f6;">{{ user.invoicingDetails.name }}</b></p>
                                </vs-row>
                                <template #tooltip>
                                    <div style="min-width: 200px; padding-top: 10px;">
                                        <vs-row>
                                            <vs-button @click="$router.push('/user/account')" transparent block icon><i class='bx bx-sm bxs-user-circle'></i> Account</vs-button>
                                        </vs-row>
                                        <vs-row>
                                            <vs-button @click="$router.push('/drafts')" transparent block icon><i class='bx bx-sm bx-layout'></i> I miei progetti</vs-button>
                                        </vs-row>
                                        <vs-row>
                                            <vs-button @click="$router.push('/user/orders')" transparent block icon><i class='bx bx-sm bxs-package'></i> I miei ordini</vs-button>
                                        </vs-row>
                                        <vs-row>
                                            <vs-button transparent block icon :loading="logoutLoading" @click="logout"><i class='bx bx-sm bx-log-out-circle'></i> Disconnetti</vs-button>
                                        </vs-row>
                                    </div>

                                </template>
                            </vs-tooltip>
                        </div>
                    </vs-row>

                </div>
                <button class="header__burger" @click="burgerMenu = !burgerMenu" :class="{'active': burgerMenu}"></button>
            </div>
        </header>
        <router-view></router-view>
        <div class="footer" style="padding-top: 60px;" :style="{marginTop: ($route.name == 'homepage' || $route.name == 'store' ? '0' : '50px'), paddingTop: $route.name == 'store' ? '0' : undefined}" v-if="$route.name != 'admin' && $route.name != 'store'" :class="{'builder-footer': $route.name == 'store'}">
            <div class="footer__center center">
                <div class="footer__body" v-if="$route.name != 'store'">

                    <div class="footer__line">
                        <div class="footer__wrap"><a class="footer__logo"><img class="footer__pic footer__pic_dark" src="./assets/logo-dark.png" alt="" /><img class="footer__pic footer__pic_light" src="./assets/logo-dark.png" alt="" /></a>
                            <div class="footer__text">Non essere la comparsa nella storia di qualcun altro, Sii il protagonista della tua storia.</div>
                        </div>
                        <div class="footer__nav">
                            <router-link to="/tos" class="footer__link">Termini di servizio</router-link>
                            <router-link to="/privacy-policy" class="footer__link">Privacy & Policy</router-link>
                            <router-link to="/cookie-policy" class="footer__link">Cookie Policy</router-link>
                        </div>
                    </div>
                </div>
                <div class="footer__bottom">
                    <div class="footer__copyright">© RemindMe </div>
                    <div class="footer__social"><a class="footer__link"><svg class="icon icon-facebook">
                                <use xlink:href="img/sprite.svg#icon-facebook"></use>
                            </svg></a><a class="footer__link"><svg class="icon icon-twitter">
                                <use xlink:href="img/sprite.svg#icon-twitter"></use>
                            </svg></a><a class="footer__link"><svg class="icon icon-instagram">
                                <use xlink:href="img/sprite.svg#icon-instagram"></use>
                            </svg></a></div>
                </div>
            </div>
            <!--<label class="switch js-switch-theme"><input class="switch__input" type="checkbox" v-model="isDark" /><span class="switch__in"><span class="switch__box"></span><span class="switch__icon switch__icon_dark"><svg class="icon icon-dark">
                            <use xlink:href="img/sprite.svg#icon-dark"></use>
                        </svg></span><span class="switch__icon switch__icon_light"><svg class="icon icon-light">
                            <use xlink:href="img/sprite.svg#icon-light"></use>
                        </svg></span></span></label>-->
        </div>
    </div>
    <cookie-law ref="cookieLaw" theme="dark-lime" buttonText="Ho capito" buttonDeclineClass="btn btn_transparent" buttonClass="main__btn btn btn_orange" :buttonDecline="true" buttonDeclineText="Non consentire">
        <div slot="message">
            Questo sito utilizza i cookie per assicurarsi che tu possa avere un esperienza migliore.
        </div>
    </cookie-law>

    <vue-whatsapp-widget phoneNumber="+393669971709" textReply="Ti risponderemo a breve." :messages="['Come possiamo aiutarti?']" companyName="RemindMe" companyLogo="/logo.png" />

</div>
</template>

<script>
import VueWhatsappWidget from './components/WhatsappWidget/Widget.vue'
import CookieLaw from 'vue-cookie-law'
import {
    apiCall,
    baseUrl
} from './client';
import AOS from 'aos';

var sha512 = require('sha512')

export default {
    name: 'App',
    components: {
        VueWhatsappWidget,
        CookieLaw
    },
    data() {
        return {
            active: '',
            user: null,
            userLoaded: false,
            signInDialog: false,
            signUpDialog: false,
            userLoading: false,

            email: '',
            nome: '',
            password: '',
            rpassword: '',

            theme: '',
            isDark: false,

            burgerMenu: false,
            baseUrl,
            logoutLoading: false,

            cookieDialog: false,
            cookieDialogIntent: null,

            userMenu: false,

            ctaProductId: null,

            tos: false,

            resetPasswordDialog: false,
            resetPasswordDialogLoading: false,
            resetPasswordDialogPassword: '',
            resetPasswordDialogRPassword: '',
            resetPasswordToken: '',

            recoverPasswordDialog: false,
            recoverPasswordDialogLoading: false,
            recoverPasswordDialogEmail: ''
        }
    },

    methods: {
        async recoverPassword(){
            this.recoverPasswordDialogLoading = true;
            var result = await apiCall('POST', '/User/Recover', {
                Email: this.recoverPasswordDialogEmail
            });
            this.recoverPasswordDialogLoading = false;
            if (result.status == 200) {
                // success message
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Benvenuto!',
                    text: `Ti abbiamo inviato una mail con le istruzioni per reimpostare la password!`
                })
                this.recoverPasswordDialog = false;
            } else {
                // error message
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops! :(',
                    text: `Impossibile recuperare la password, controlla che l'email sia corretta.`
                })
            }
        },

        showRecoverPassword(){
            this.recoverPasswordDialog = true;
            this.signInDialog = false;
            this.signUpDialog = false;
            this.resetPasswordDialog = false;
            this.burgerMenu = false;
        },

        async resetPassword(){
            if (this.resetPasswordDialogPassword != this.resetPasswordDialogRPassword) {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Le password non combaciano.`
                })
                return;
            }

            this.resetPasswordDialogLoading = true;

            var result = await apiCall('POST', '/User/Reset?session=' + this.resetPasswordToken, {
                Password: sha512(this.resetPasswordDialogPassword).toString('hex')
            });

            this.resetPasswordDialogLoading = false;
            if (result.status == 200) {
                // success message
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Benvenuto!',
                    text: `Password reimpostata con successo, effettua l'accesso!`
                })
                this.resetPasswordToken = '';
                this.showSignIn();
            } else {
                // error message
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile reimpostare la password, il link potrebbe essere scaduto.`
                })
            }
        },

        async verifyUser() {
            var result = await apiCall('POST', '/User/Verify?session=' + this.$route.query.token);
            // clear query params
            this.$router.replace({
                query: null
            });
            if (result.status == 200) {
                // success message
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Benvenuto!',
                    text: `Il tuo account è stato verificato con successo!`
                })
            } else {
                // error message
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile verificare il tuo account.`
                })
            }
        },


        acceptCookie() {
            this.$refs.cookieLaw.accept();
            this.cookieDialogIntent();
            this.cookieDialog = false;
        },
        showSignIn() {
            if (localStorage.getItem('cookie:accepted') != 'true') {
                this.cookieDialogIntent = this.showSignIn;
                this.cookieDialog = true;
                return;
            }
            this.signInDialog = true;
            this.signUpDialog = false;
            this.resetPasswordDialog = false;
            this.burgerMenu = false;
        },

        showSignUp() {
            if (localStorage.getItem('cookie:accepted') != 'true') {
                this.cookieDialogIntent = this.showSignUp;
                this.cookieDialog = true;
                return;
            }
            this.signInDialog = false;
            this.signUpDialog = true;
            this.resetPasswordDialog = false;
            this.burgerMenu = false;
        },

        async signIn() {
            this.userLoading = true;
            var result = await apiCall('POST', '/User', {
                Email: this.email,
                Password: sha512(this.password).toString('hex')
            });
            this.userLoading = false;
            if (result.status == 200) {
                //localStorage.setItem('JWTToken', result.data.token);
                await this.getUserData();
                if (this.ctaProductId != null) { // direct to builder
                    this.$router.push('/store/' + this.ctaProductId);
                    this.ctaProductId = null;
                    return;
                }
                this.$router.push({
                    name: 'feed'
                })
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Credenziali non valide.`
                })
            }
        },

        async signUp() {
            if (this.password != this.rpassword) {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Le password non combaciano.`
                })
                return;
            }

            if (this.nome.length < 1) {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Compilare il campo nome e riprovare.`
                })
                return;
            }

            if (!this.tos) {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Devi accettare i termini di servizio per poter proseguire.`
                })
                return;
            }
            this.userLoading = true;
            var result = await apiCall('PUT', '/User', {
                Email: this.email,
                Password: sha512(this.password).toString('hex'),
                Name: this.nome
            });
            this.userLoading = false;
            if (result.status == 200) {
                await this.signIn();
            } else if (result.status == 400) {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Sembra che tu sia già registrato!`
                })
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile completare la registrazione.`
                })
            }
        },

        async getUserData() {
            this.userLoading = true;
            var result = await apiCall('GET', '/User');
            this.userLoading = false;
            if (result.status == 200) {
                this.user = result.data;
            }
        },

        canUserBeThere() { // this function ensure that the user has the right permissions for viewing a router page

            if (this.$route.meta.loggedIn && this.user == null) {
                this.$router.push({
                    name: 'homepage'
                });
                return;
            }

            if (this.$route.meta.isAdmin && (this.user == null || !this.user.isAdmin)) {
                this.$router.push({
                    name: 'homepage'
                });
                return;
            }
        },

        async logout() {
            var vm = this;

            if(vm.$route.name == 'store'){
                const answer = window.confirm('Se abbandoni questa pagina tutte le modifiche verranno perse.')
                if (!answer) {
                    return;
                }
            }


            this.logoutLoading = true;
            var result = await apiCall('POST', '/User/Logout');
            this.logoutLoading = false;
            if (result.status == 200) {
                this.userMenu = false;
                setTimeout(function () { // after 1s, otherwise the user menu doesnt have time to be closed and it breaks up
                    vm.user = null;
                    vm.canUserBeThere();
                }, 1000);
            }
        }
    },

    async mounted() {
        AOS.init();
        await this.getUserData();
        this.userLoaded = true;

        this.theme = localStorage.getItem('theme');
        this.isDark = localStorage.getItem('theme') == 'dark';

        if(this.$route.query.token != null){
            this.verifyUser();
        }

        // if reset param is set 
        if(this.$route.query.reset != null){
            this.resetPasswordToken = this.$route.query.reset;
            this.resetPasswordDialog = true;
            this.$router.replace({
                query: null
            });
        }
    },

    watch: {
        resetPasswordDialog(){
            this.resetPasswordDialogPassword = '';
            this.resetPasswordDialogRPassword = '';
        },

        user(n) {
            if (n != null) {
                this.signInDialog = false;
                this.signUpDialog = false;
            }
        },

        burgerMenu(n) {
            if (n) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        },

        userLoaded(n) {
            if (n) {
                this.canUserBeThere();
            }

        },

        theme(n) {
            if (n != null) {
                if (n == 'dark') {
                    window.document.body.className = 'dark';
                } else if (n == 'light') {
                    window.document.body.className = '';
                }
                localStorage.setItem('theme', n)
            }

        },

        isDark(n) {
            if (n) {
                this.theme = 'dark';
            } else {
                this.theme = 'light';
            }
        },

        '$route.name'(n) {
            if (n != null) {

                this.burgerMenu = false;

                if (n == 'store') {
                    window.onbeforeunload = function () {
                        return "Se abbandoni questa pagina tutte le modifiche verranno perse.";
                    }
                } else {
                    window.onbeforeunload = null;
                }

                if (this.userLoaded) {
                    this.canUserBeThere();
                }
            }
        },
        active(n) {
            if (n != this.$route.name) {
                this.$router.push({
                    name: n
                })
            }
        }
    }
}
</script>

<style>
body:has(.vs-dialog) {
    overflow: hidden!important;
}

.con-form {

    display: flex;
    flex-direction: column;
    gap: 8px;

}

@import './assets/homepage.css';

body {
    font-family: "Poppins";
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}


body,html,#app {
    height: 100%;
}


.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.25s ease-out;
}

.slide-up-enter-from {
    opacity: 0;
    transform: translateY(30px);
}

.slide-up-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d793ff;
    border-radius: 20px;
    border: 0px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #b648f6;
}

input.vs-input {
    width: 100% !important;
    height: 45px;
}

textarea {
    border-style: none;
    border-color: Transparent;
    overflow: auto;
    border: 1px solid #d1d1d1;
    width: 100%;
    max-width: 100%;
    min-height: 10vh;
    height: 100%;
    border-radius: 10px;
    font-family: 'Poppins';
    box-sizing: border-box;
    min-width: 100%;
    max-height: 40vh;
    padding: 6px;
    font-size: 14px;
    color: rgb(0 0 0 / 40%);
    outline: none;
}

input.vs-select__input {
    min-height: 44px;
}

input[type=color].vs-input {
    padding: 2px;
}

.cropper-wrapper {
    overflow: hidden;
    position: relative;
    height: 500px;
    background: black;
}

.cropper-background {
    background: none;
}

.image-background {
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    left: -10px;
    top: -10px;
    background-size: cover;
    background-position: 50%;
    filter: blur(5px);
}

.vue-advanced-cropper__background,
.vue-advanced-cropper__foreground {
    background: rgba(0, 0, 0, 0.4);
}

.header__btn {
    cursor: pointer;
}

.new a {
    cursor: pointer;
    text-decoration: underline;
}

.vs-tooltip {
    background: rgba(var(--vs-light), 1) !important;
    color: rgba(var(--vs-text), 1) !important;
}

.vs-tooltip button {
    color: rgba(0, 0, 0, 0.8);
    font-size: 15px;
}

.vs-button--gradient.vs-button--primary:before,
.vs-button--gradient.vs-button--success:before {
    filter: hue-rotate(245deg);
}

.vs-avatar {
    color: rgba(var(--vs-primary), 1) !important;
    background: rgb(255 248 184) !important;
}

.vs-avatar:has(img) {
    background: white !important;
}

.mobile-only {
    display: none!important;
}
.footer__logo {
        margin-top: 20px;
    }
/* MOBILE */
@media (max-width: 768px) {
    .header__btns .vs-button {
        width: 49%;
    }
    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: block!important;
    }



    .vs-sidebar-content.absolute {
        z-index: 9!important;
    }
}

button.blantershow-chat {
    z-index: 5 !important;
}

.header__wrapper.visible {
    z-index: 10;
}

.next,
.prev,
.trail {
    z-index: 9 !important;
}

.whatsapp-chat-name-block br {
    display: none;
}

.whatsapp-chat-name-block {
    display: flex;
    flex-direction: column;
}

.footer.builder-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
}


.vs-button.animate {
    /* animate background white gradient */
    background: linear-gradient(60deg, rgba(var(--vs-primary), 0.7), rgba(var(--vs-primary), 1), rgba(var(--vs-primary), 0.7));
    background-size: 200% 100%;
    animation: animate 4s ease infinite alternate;
}

@keyframes animate {
    0% {
        background-position: 200% 0;
        box-shadow: 0px 10px 35px 0px rgba(204, 68, 159, 0.6);
    }
    100% {
        background-position: -200% 0;
        box-shadow: 50px 10px 35px 0px rgba(195, 68, 204, 0.2);
    }
}
</style>
